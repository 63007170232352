@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.container {
  z-index: 999;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  padding-top: toRem(20);
  padding-bottom: toRem(20);
  justify-content: center;

  background-color: $grey900;
  .actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .description {
    font-size: toRem(13);
    color: $white;
    max-width: toRem(650);
    width: 100%;
  }
  .submitButton {
    border-radius: toRem(20);
    padding: toRem(10) toRem(20);
    color: $white;
    font-size: toRem(15);
    margin-left: toRem(55);
  }
  .close {
    color: $white;
    font-size: toRem(15);
    margin-left: toRem(30);
  }
}
@include respond(mobile) {
  .container {
    padding-left: toRem(10);
    padding-right: toRem(10);
    justify-content: flex-start;
    .actions {
      margin-top: toRem(15);
    }
    .submitButton {
      margin-left: 0;
    }
  }

  .description {
    padding-right: 60px;
  }
}
