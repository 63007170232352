@import '@styles/palette.module.scss';
@import '@styles/functions.module.scss';

.authButtonsMobile {
  display: none;
}

@include respond(mobile) {
  .authButtonsMobile {
    flex: 1 1;
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;

    & button, a {
      min-width: 121px;
      font-size: 14px;
      padding: toRem(10) toRem(15);
    }
  }
}
