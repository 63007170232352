@import '@styles/palette.module.scss';
@import '@styles/functions.module.scss';

.appBarButton {
  color: $white;

  &.loginButton {
    color: $grey900;
    font-size: 1rem;
  }

  &:last-child {
    padding: 0.687rem 0.437rem;
  }
}

@include respond(mobile) {
  .appBarButton {
    width: 100%;
    font-size: 0.875rem;
    height: 2.625rem;
    margin-bottom: 0.5rem;
  }
}
