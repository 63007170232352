@import '@/styles/functions.module.scss';
@import '@/styles/palette.module.scss';

.link {
  display: flex;
  align-items: center;
  font-size: toRem(16);
  font-weight: 700;
  color: $blue500;
  margin-top: auto;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  &:after {
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $blue500;
    transform: translate3d(-100%, 0, 0);
    content: '';
    width: 100%;
    transition:
      opacity 300ms,
      transform 300ms;
    height: 1px;
  }
  svg {
    transition: 0.3s;
    margin-left: toRem(10);
    width: toRem(18);
  }
  &:hover {
    &:after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.black span {
  position: relative;
  color: $black;
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid $black;
  padding-bottom: -1px;
  font-size: toRem(16);
  line-height: toRem(16);
  white-space: nowrap;

  &:hover {
    background-color: $white;
  }
}
