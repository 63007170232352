@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.Container {
  background-color: $blue500;
  height: toRem(820);
  padding: toRem(73) toRem(103) toRem(78) toRem(73);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: toRem(100);
  overflow: hidden;
  @include mobile {
    height: auto;
    margin-bottom: toRem(50);
    padding: toRem(46) toRem(20) toRem(26);

  }


  &::before,
  &::after {
    content: '';
    position: absolute;
    background: $white;
    opacity: 0.15;
    border-radius: 50%;
    z-index: 0;
  }

  &::before {
    top: toRem(-85);
    right: toRem(-125);
    width: toRem(385);
    height: toRem(385);

    @include mobile {
      top: toRem(-75);
      right: toRem(-75);
      width: toRem(180);
      height: toRem(180);
    }
  }

  &::after {
    left: toRem(-485);
    bottom: toRem(-585);
    width: toRem(1000);
    height: toRem(1000);

    @include mobile {
      left: toRem(-885);
      bottom: toRem(-485);
    }
  }

  & > div {
    position: relative;
    z-index: 1;
  }
}
