@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.carouselLoader {
  min-height: toRem(332);

  @include respond(mobile) {
    min-height: 24.68rem;
  }
}

.root {
  width: toRem(1060);
  border-radius: toRem(20);
  box-shadow: 0 toRem(20) toRem(40) 0 #eef0f4;
  background-color: $white;
}

.carouselItem {
  border-radius: toRem(20);
  background-color: $white;
  padding: toRem(30) toRem(160);
  position: relative;
  border-radius: 20px;
}

.carouselItemIcon {
  width: toRem(86);
  position: absolute;
  top: toRem(10);
  left: toRem(120);
}

.carouselItemText {
  font-weight: normal;
  line-height: 1.2;
}

.userInfo {
  margin-top: toRem(15);
  display: flex;
  align-items: center;
}

.userAvatar {
  width: toRem(64);
  height: toRem(64);
  border-radius: 50%;
  margin-right: toRem(15);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.userName {
  font-size: toRem(18);
  line-height: 1.06;
}

.userPosition {
  font-size: toRem(13);
  color: $grey500;
  line-height: 1.46;
  margin-top: roRem(3);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: toRem(50);
}

.pagination {
  display: flex;
  align-items: center;
  margin: 0 toRem(90);
  :global {
    .swiper-pagination-bullet {
      width: toRem(21);
      height: toRem(21);
      border-radius: 50%;
      margin: 0 toRem(5);
      display: inline-block;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:before {
        content: '';
        width: toRem(15);
        height: toRem(15);
        border-radius: 50%;
        display: inline-block;
        border: toRem(3) solid transparent;
        background-color: $blue500;
        opacity: 0.5;
        z-index: 1;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $blue500;
      &:before {
        border: toRem(3) solid $white;
        opacity: 1;
      }
    }
  }
}

.prevBtn,
.nextBtn {
  width: toRem(47);
  height: toRem(47);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: toRem(16);
  border: solid 1px $grey400;
  background-color: $white;
  cursor: pointer;
  &:hover {
    background-color: $blue500;
    border-color: $blue500;
    svg {
      color: $white;
    }
  }

  svg {
    width: toRem(22);
    color: $grey400;
  }
}

@include respond(mobile) {
  .root {
    width: 100%;
  }

  .carouselItem {
    padding: toRem(55) toRem(22) toRem(20);
    border-radius: 0;
    background-color: unset;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    margin-top: toRem(40);
  }

  .pagination {
    margin: 0;
  }

  .carouselItemText {
    font-size: toRem(15);
    line-height: 1.6;
  }

  .carouselItemIcon {
    left: toRem(15);
    top: toRem(20);
  }
}
