@import '@styles/palette.module.scss';
@import '@styles/functions.module.scss';

.subNav {
  position: absolute;
  background-color: $white;
  border-top: 1px solid $grey300;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 3.75rem 2rem;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: 100%;
  transform: translateY(-110%);
  z-index: -2;
  width: 100%;
  transition: all 200ms ease;
  will-change: transform, opacity;
}

.subNavList {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: toRem(45);
  grid-row-gap: toRem(20);
}

.subNavListItem {
  padding: 0;
}

.subNavItem {
  display: flex;
  height: 100%;
  text-decoration: none;
  color: $grey900;
}
.subNavItem:hover {
  .subNavItemTitle:after {
    transform: translateX(0);
    opacity: 1;
  }
}
.subNavItemIcon {
  min-width: 1.75rem;
  margin-right: 0.625rem;
}

.subNavItemTitle {
  margin-bottom: 0.5rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
  &:after {
    position: absolute;
    background-color: $grey900;
    height: 2px;
    opacity: 0;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    transition:
      opacity 300ms,
      transform 300ms;
  }
}

.subNavItemDesc {
  color: $grey500;
}

@include respond(mobile) {
  .subNav {
    padding: 0;
    position: relative;
    transform: none;
    visibility: visible;
    opacity: 1;
    top: 0;
    box-shadow: none;
    z-index: 0;
    border: none;
    display: none;
    width: 100%;
  }

  .subNavContainer {
    padding: 0;
  }

  .subNavList {
    display: block;
  }

  .subNavListItem {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $grey300;
  }

  .subNavItemIcon {
    display: none;
  }

  .subNavItemTitle {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0;
  }

  .subNavItemDesc {
    display: none;
  }
}
