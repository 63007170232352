@import '../../styles/palette.module.scss';
@import '../../styles/functions.module.scss';

.footer {
  background-color: $white;
  padding-top: 4.1875rem;
}
.container {
  max-width: 1600px;
  margin: 0 auto;
}
.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.logo {
  width: toRem(150);
}

.social {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.socialLink {
  width: 2.25rem;
  height: 2.25rem;
}

.nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: 5rem;
  margin-bottom: 3.25rem;
}

.navItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navLabel {
  font-weight: 900;
  font-size: 1.25rem;
}

.navList {
  margin-top: 2rem;
  padding: 0;
}

.navListItem {
  margin-bottom: 0.75rem;
  padding: 0;
}

.navLink {
  font-size: 1rem;
  color: $grey900;
  text-decoration: none;
  &:hover {
    color: $blue500;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.navDesc {
  font-size: 1rem;
  color: $grey900;
  margin-top: 0.625rem;
}

.navIcoLink {
  width: 16.25rem;
  margin-top: 1.75rem;
}

.bottomInfo {
  border-top: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
}

.disclaimer {
  color: $grey500;
}

.bottomInfoLinks {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.bottomInfoLink {
  font-size: 0.875rem;
  color: $grey900;
  text-decoration: none;
  &:hover {
    color: $blue500;
    cursor: pointer;
  }
}

@include respond(mobile) {
  .logo {
    width: 9.75rem;
  }

  .nav {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 50px;
    margin-bottom: 2.125rem;

    grid-template-areas:
      'a c'
      'b b'
      'd d';
  }

  .head {
    margin-bottom: 3.125rem;
  }

  .navItem {
    width: 100%;

    &:first-child {
      grid-area: a;
    }

    &:nth-child(2) {
      grid-area: b;
    }

    &:nth-child(3) {
      grid-area: c;
    }

    &:nth-child(4) {
      grid-area: d;
    }
  }

  .bottomInfo {
    display: block;
    height: auto;
  }

  .disclaimer {
    display: none;
  }

  .bottomInfoLinks {
    flex-direction: column;
    align-items: start;
    padding: 1.75rem 0;
    gap: 1.25rem;
  }
}
