@import '@styles/palette.module.scss';
@import '@styles/functions.module.scss';

.navListItem {
  position: static;
  padding: 0;
  height: 100%;
  display: flex;
  &.opened {
    .navListItemLink {
      color: $orange500;
    }

    :global(.subnav) {
      height: auto;
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
      z-index: -1;
    }

    .navListItemLinkIcon {
      transform: rotateX(180deg);
    }
  }
}

.navListItemLink {
  height: 100%;
  text-decoration: none;
  color: $grey900;
  white-space: nowrap;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 1.25rem;
  transition: transform 0.3s ease-in;
  font-weight: 500;

  &.noArrow {
    padding-right: 0;
  }

  &:hover {
    color: $orange500;
  }
}

.navListItemLinkIcon {
  position: absolute;
  width: 0.95rem;
  height: 0.95rem;
  right: 0;
  top: calc(50% - 0.41rem);
  display: flex;

  svg {
    width: inherit;
    height: inherit;
  }
}

@include respond(mobile) {
  .navListItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    &.opened {
      .navListItemLinkIcon {
        transform: rotate(180deg);
      }

      :global(.subnav) {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        z-index: 1;
        display: block;
      }
    }
  }

  .navListItemLink {
    font-size: 1.125rem;
    font-weight: 500;
    width: 100%;
  }

  .navListItemLinkIcon {
    width: 1rem;
    height: 1rem;
    transform: rotate(-90deg);
  }
}
