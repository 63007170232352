@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.buttons.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: toRem(30);

  & > button,
  & > a {
    padding: 0.75rem 1.725rem;
    width: toRem(145);
    height: toRem(44);
    font-size: toRem(16);
    white-space: nowrap;
  }
}

.learnMore span {
  position: relative;
  color: $black;
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid $black;
  padding-bottom: -1px;
  font-size: toRem(16);
  line-height: toRem(16);
  white-space: nowrap;
}

@include respond(mobile) {
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > button,
    & > a {
      width: calc(50% - toRem(5));
      height: toRem(44);
      font-size: toRem(16);
    }
  }
}
