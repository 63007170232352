@import '@/styles/dimensions.module';

.wrapper{
  max-width: 725px;
  width: 100%;
  margin: auto;
  display: flex;
}
.container{
  padding: 0;
  background-color: transparent;
  position: relative;
  align-self: center;
}
.close{
  position: absolute;
  right: -20px;
  top: -40px;
  svg{
    width: 20px;
  }
}
@media screen and (max-width: $tabletBreakpoint) {
  .container{
    max-width: 100%;
    margin: 40px;
  }
}
@media screen and (max-width: $mobileBreakpoint) {
  .container{
    margin: 20px
  }
}
@media screen and (max-width: $sxMobileBreakpoint) {
  .container{
    margin: 10px
  }
}