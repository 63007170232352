@import '@styles/palette.module.scss';
@import '@styles/functions.module.scss';

.nav {
  height: 100%;
  margin: 0 auto;
}

.navList {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2.375rem;
  position: static;
  margin-left: 3.125rem;
  padding: 0;
}

@include respond(mobile) {
  .nav {
    height: initial;
    margin: unset;
  }

  .navList {
    flex-direction: column;
    margin-left: 0;
    gap: 0;
  }
}
