@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.roundBackgroundRoot {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $orange200;
}
.circle{
  background: $white;
  position: absolute;
  opacity: 0.5;
  &Large{
    width: toRem(560);
    height: toRem(560);
    bottom: 0;
    left: 0;
    border-radius: 0 100% 0 0;
  }
  &Small{
    width: toRem(185);
    height: toRem(185);
    top: 0;
    right: 0;
    border-radius: 0 0 0 100%;
  }
}
