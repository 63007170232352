@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.section {
  width: 100%;
  overflow: hidden;
  padding: toRem(110) 0 toRem(20) 0;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  position: relative;
  padding: 0 0 0 toRem(138);
  display: grid;
  grid-template-columns: toRem(510) 1fr;
  grid-gap: toRem(20);
}

.body {
  max-width: toRem(510);
}

.subtitle {
  margin-top: toRem(20);
  font-size: 20px;
}

.notion {
  margin-top: toRem(20);
  color: $grey500;
}

.form {
  margin-top: toRem(40);
}

.image {
  img {
    width: 100%;
    height: auto;
  }
}

@include respond(mobile) {
  .section {
    padding: toRem(25) 0;
    min-height: initial;
  }

  .container {
    padding: 0 toRem(20);

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: toRem(20);
  }

  .image {
    margin-left: toRem(-20);
    margin-right: toRem(-20);
    width: auto;
  }

  .title {
    font-size: toRem(42);
  }

  .subtitle {
    margin-top: toRem(30);
    font-weight: 500;
  }

  .body {
    max-width: 100%;
  }

  .form {
    margin: toRem(50) 0;
  }
}
