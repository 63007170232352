@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  background-color: $orange250;
  padding-top: toRem(60);
  padding-bottom: toRem(50);

  @include respond(mobile) {
    padding-bottom: 0;
  }
}

.title {
  white-space: pre-wrap;
  text-align: center;
  font-size: toRem(48);
  font-weight: 900;

  @include respond(mobile) {
    white-space: normal;
    text-align: left;
    font-size: 36px;
  }
}

.contentWrapper {
  margin-top: toRem(20);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: toRem(80);

  @include respond(mobile) {
    flex-direction: column;
    gap: 20px;
  }
}

.listTitle {
  white-space: pre-wrap;
  font-size: toRem(24);
  line-height: toRem(35);
}

.listItem {
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: toRem(7);
  font-weight: 500;
  padding-top: toRem(6);
  padding-bottom: toRem(6);

  svg {
    width: toRem(24);
    height: toRem(24);
  }
}

.link {
  width: toRem(260);
  height: toRem(55);
  display: block;
  margin-top: toRem(30);
  position: relative;

  @include respond(mobile) {
    margin: toRem(20) auto 0;
  }
}

.imagePart {
  position: relative;

  @include respond(mobile) {
    width: 100%;
  }
}

.imageWrapper {
  position: relative;
  width: toRem(600);
  height: toRem(490);
  z-index: 2;

  @include respond(mobile) {
    width: 100%;
    height: 370px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.circle {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $orange500;
  background-image: var(--pw-gradient);
  background-size: 100%;
  right: 0;
  bottom: 0;
  z-index: 1;

  &:nth-child(1) {
    top: 14%;
    left: 4%;
    width: toRem(60);
    height: toRem(60);
  }

  &:nth-child(2) {
    width: toRem(150);
    height: toRem(150);
    top: 60%;
    left: -3%;
  }

  &:nth-child(3) {
    width: toRem(94);
    height: toRem(94);
    top: 0;
    right: 4%;
  }

  &:nth-child(4) {
    width: toRem(20);
    height: toRem(20);
    top: 28%;
    right: 2%;
  }

  &:nth-child(5) {
    width: toRem(15);
    height: toRem(15);
    bottom: 10%;
    right: 7%;
  }
}
