@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.root {
  width: toRem(1060);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: toRem(50);
}

.pagination {
  display: flex;
  align-items: center;
  margin: 0 toRem(90);

  :global {
    .swiper-pagination-bullet {
      width: toRem(21);
      height: toRem(21);
      border-radius: 50%;
      margin: 0 toRem(5);
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:before {
        content: '';
        width: toRem(15);
        height: toRem(15);
        border-radius: 50%;
        display: inline-block;
        background-image: var(--pw-gradient);
        opacity: 0.5;
        z-index: 1;
      }
    }

    .swiper-pagination-bullet-active {
      background-image: var(--pw-gradient);

      &:before {
        border: toRem(3) solid $white;
        opacity: 1;
      }
    }
  }
}

.prevBtn,
.nextBtn {
  width: toRem(47);
  height: toRem(47);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: toRem(16);
  border: solid 1px $grey900;
  background-color: $white;
  cursor: pointer;
  background-size: 53px 53px;
  background-position: center;

  &:hover {
    background-image: var(--pw-gradient);
    border-color: transparent;

    svg {
      color: $white;
    }
  }

  svg {
    width: toRem(22);
  }
}

@include respond(mobile) {
  .root {
    width: 100%;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    margin-top: toRem(40);
  }

  .pagination {
    margin: 0;
  }
}
