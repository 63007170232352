@import '@/styles/palette.module.scss';
@import '@/styles/functions.module.scss';

.error {
  padding: toRem(15);
  font-size: toRem(13);
  margin-top: toRem(8);
  border-radius: toRem(10);
  color: $red500;
  background-color: $red200;
}
