@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: toRem(90);
}

.rootReverse {
  flex-direction: row-reverse;
}

.item {
  max-width: toRem(505);
}

.itemTitle {
  margin-bottom: toRem(20);
  font-size: toRem(34);
  white-space: pre-wrap;
  font-weight: 900;
}

.itemText {
  font-weight: normal;
  color: $grey500;
  font-size: toRem(18);
}
.itemImage {
  max-width: 600px;
  width: 100%;
}
@include respond(mobile) {
  .root {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: toRem(25);
  }
  .itemImage {
    align-self: center;
  }
  .itemTitle {
    font-size: toRem(27);
    margin-bottom: toRem(24);
  }

  .itemText {
    font-size: toRem(17);
    font-weight: normal;
  }
}
