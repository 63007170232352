@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.section {
  padding: toRem(50) 0;

  &.gradient {
    background-image: linear-gradient(to bottom, $white -29%, $blue100);
  }

  &.grey {
    background-color: #f6f8fa;
  }
}

.title {
  text-align: center;
  font-weight: 900;
}

.subtitle {
  text-align: center;
  margin-top: toRem(10);
  margin-bottom: toRem(50);
}

.container {
  :global(.swiper) {
    box-shadow: 0 20px 40px 0 rgba(214, 221, 237, 0.8);
    border-radius: 20px;
  }
}

@include respond(mobile) {
  .section {
    padding: toRem(40) 0;
  }

  .title {
    font-size: 27px;
    font-weight: 900;
  }

  .subtitle {
    font-size: toRem(19);
    margin-bottom: toRem(45);
  }
}
