@import '../../styles/functions.module.scss';

.container {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  padding-left: toRem(86);
  padding-right: toRem(86);
}

@include respond(mobile) {
  .container {
    padding-left: toRem(20);
    padding-right: toRem(20);
  }
}
