@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  margin: toRem(100) 0;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  gap: toRem(47);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: toRem(25);
}

.title {
  font-weight: 900;
  font-size: toRem(34);
  white-space: pre-wrap;
}

.description {
  color: $grey500;
  font-size: toRem(18);
  max-width: toRem(505);
}

.animationWrapper {
  display: flex;
  justify-content: center;
  width: toRem(630);

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

@include respond(mobile) {
  .section {
    margin: toRem(80) 0 toRem(50) 0;
  }

  .title {
    font-size: toRem(27);
    line-height: 1.1;
  }

  .image {
    margin: 0 toRem(-20);
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: toRem(30);
  }

  .animationWrapper {
    width: auto;
  }
}
