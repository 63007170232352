@import '../../styles/palette.module.scss';
@import '../../styles/functions.module.scss';

.Root {
  padding: 0;
  width: 100%;

  &::before,
  &::after {
    display: none;
  }

  &:global(.MuiInputBase-multiline) {
    width: 100%;
  }
}

.Input {
  background-color: $grey200;
  font-size: toRem(15);
  padding: toRem(12) toRem(20) toRem(12) toRem(12);
  border-radius: toRem(10);
  border: 2px solid transparent;
  outline: none;
  color: $blue900;
  transition: all 200ms ease;
  caret-color: $blue500;
  width: 100%;

  &::placeholder {
    color: $grey500;
  }

  &:hover,
  &:focus {
    border-color: $blue500;
  }

  &[aria-invalid='true'] {
    color: $red500;
    background-color: $red200;

    &:focus {
      border-color: $red500;
    }

    &:hover {
      border-color: $red500;
    }
  }
}

.ErrorMessage {
  padding: toRem(15);
  font-size: toRem(13);
  margin-top: toRem(8);
  border-radius: toRem(10);
  color: $red500;
  background-color: $red200;
}
