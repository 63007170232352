@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.section {
  background-color: $blue500;
  padding: toRem(50) 0;
}

.body {
  max-width: toRem(720);
  margin: 0 auto;
  color: $white;
  position: relative;
}

.title {
  text-align: center;
  margin-bottom: toRem(60);
}

.arrowIcon {
  width: toRem(84);
  position: absolute;
  right: toRem(-40);
  top: toRem(120);
}

.feedbackItem {
  display: flex;
}

.feedbackItemContainer {
  position: relative;
}

.feedbackItemParagraphIcon {
  position: absolute;
  left: toRem(-10);
  top: toRem(-30);
  width: toRem(86);
}

.feedbackItemAvatar {
  min-width: toRem(96);
  height: toRem(96);
  width: toRem(96);
  margin-right: toRem(30);
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.feedbackItemText {
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: toRem(10);
}

.feedbackItemUserName,
.feedbackItemUserPosition {
  font-weight: normal;
}

.feedbackItemRating {
  display: flex;
  gap: toRem(3);
  margin-top: toRem(10);
  svg {
    width: toRem(14);
  }
}

@include respond(mobile) {
  .section {
    padding: toRem(40) 0;
  }

  .title {
    font-size: toRem(27);
    margin-bottom: toRem(47);
    text-align: left;
  }

  .feedbackItem {
    flex-direction: column-reverse;
  }

  .feedbackItemParagraphIcon {
    left: 0;
  }

  .feedbackItemPersonalInfo {
    padding-left: toRem(96);
    min-height: toRem(76);
  }

  .feedbackItemAvatar {
    min-width: toRem(76);
    width: toRem(76);
    height: toRem(76);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
