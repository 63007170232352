@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';
.breadcrumbsList {
  display: flex;
  list-style-type: none;
  align-items: center;
  margin: 0;
  padding: 0;

  &.blue {
    .itemActive {
      color: $orange500;
    }
  }
}
.item {
  color: $orange500;
  font-size: toRem(13);
}
.link {
  color: $grey900;
  font-size: toRem(13);
  text-decoration: underline;

  &.emptyUrl {
    text-decoration: none;
    cursor: auto;
  }
}
.inactiveItemContainer {
  display: flex;
  align-items: center;

  a {
    color: $grey900 !important;
  }
}
.divider {
  margin: 0 toRem(5);
  font-size: toRem(13);
  color: $grey900;
  text-decoration: none;
}

.container {
  margin-bottom: toRem(25);

  &.float {
    position: absolute;
    top: toRem(24);
    left: 0;
    z-index: 2;
  }
}

@include respond(mobile) {
  .container {
    display: none;
  }
}
