@import url('https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap');
@import url('https://assets.calendly.com/assets/external/widget.css');
@import url('swiper/css');
@import 'dimensions.module.scss';
@import 'palette.module.scss';

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

:root {
  --pw-gradient: linear-gradient(297deg, rgba(255,34,100,1) 0%, rgba(254,177,0,1) 100%, rgba(254,168,7,1) 350%);
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  scroll-behavior: smooth;
}

.pw-text-gradient {
  background-color: $orange500;
  background-image: var(--pw-gradient);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.isMobile {
  display: none;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: #d7dcea;
}

.MuiInputBase-input::placeholder {
  color: #7c8196;
}

.MuiInputBase-input::-ms-input-placeholder {
  color: #7c8196;
}

@media screen and (max-width: $laptopBreakpoint) {
  html,
  body {
    font-size: 14px;
    width: 100%;
    height: 100%;
  }

  #__next {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $mdLaptopBreakpoint) {
  html,
  body {
    font-size: 13px;
    width: 100%;
    height: 100%;
  }

  #__next {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  html,
  body {
    font-size: 10px;
    width: 100%;
    height: 100%;
  }

  #__next {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  html,
  body {
    font-size: 13px;
    width: 100%;
    height: 100%;
  }

  #__next {
    width: 100%;
    height: 100%;
  }

  .isMobile {
    display: block;
  }

  .isDesktop {
    display: none;
  }
}
