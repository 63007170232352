@import '../../styles/palette.module.scss';
@import '../../styles/functions.module.scss';

.form {
  width: toRem(452);
}

.textField {
  width: 100%;
}

.notion {
  margin-top: toRem(20);
  color: $grey500;
  display: flex;
  align-items: center;
  gap: toRem(10);

  @include respond(mobile) {
    margin-bottom: toRem(20);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > a {
      width: 50%;
    }
  }
}

.textFieldInput {
  width: 100%;
  height: toRem(64);
  border-radius: toRem(8);
  padding: toRem(21) toRem(4) toRem(21) toRem(21);
  font-size: toRem(17);
  background-color: $white;

  :global(.MuiOutlinedInput-notchedOutline) {
    border-color: $black;
  }

  &Error {
    color: $red500;

    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: $red500;

      &:active {
        border-color: $red500;
      }
    }
  }
}

.textFieldButton {
  white-space: nowrap;
  width: toRem(156);
  height: toRem(56);
  border-radius: toRem(6);
  font-size: toRem(20);
  font-weight: 500;

  &:global(.isMobile) {
    @include respond(mobile) {
      display: flex;
    }
  }
}

.disclaimerWrapper {
  margin-top: toRem(15);
  display: flex;
  flex-wrap: wrap;
  row-gap: toRem(5);
  padding: 0 toRem(4);
}

.disclaimerItem {
  display: flex;
  align-items: center;
  position: relative;
  width: 50%;
  svg {
    width: toRem(14);
    height: toRem(14);
    margin-right: toRem(6);
  }
}

.disclaimerItemCcpa {
  order: 3;
}

.disclaimerItemDefault {
  order: 2;
  justify-content: flex-end;
  margin-left: auto;
}

@include respond(mobile) {
  .form {
    width: 100%;
  }

  .textFieldInput {
    height: toRem(46);
    padding: 0;
    background-color: $white;
    input {
      height: 100%;
      box-sizing: border-box;
      padding: toRem(15);
    }
  }

  .textFieldButton {
    margin-top: toRem(10);
    height: toRem(40);
    width: 100%;
    font-size: toRem(16);
  }

  .disclaimerWrapper {
    margin-top: toRem(8);
  }

  .disclaimerItemDefault {
    order: 1;
    justify-content: flex-start;
  }

  .disclaimerItemCcpa {
    order: 3;
  }

  .disclaimerItemGdpr {
    order: 2;
  }
}
