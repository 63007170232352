@import '@styles/palette.module.scss';
@import '@styles/functions.module.scss';

.appBar {
  background-color: $white;
  box-shadow: none;
  color: $grey900;
  z-index: 9;
}

.appBarButtons {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.authButtonsMobile {
  display: none;
}

.appBarReqButton {
  font-size: 0.875rem;
  text-decoration: none;
  font-weight: 500;
  color: $grey900;
  margin-right: 1.375rem;
  &:hover {
    color: $blue500;
  }
}

.container {
  display: flex;
  align-items: center;
  height: 5rem;
  background-color: $white;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.logo {
  width: toRem(150);
}

.menu {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

@include respond(mobile) {
  .appBar {
    box-shadow: none;
    border-bottom: 1px solid $grey300;
  }

  .container {
    height: 3.75rem;
  }

  .logo {
    width: 7rem;
  }

  .menu {
    position: fixed;
    top: calc(3.75rem + 1px);
    right: -100%;
    background-color: $white;
    height: initial;
    flex-direction: column;
    width: 100%;
    padding: 2.5rem 1.25rem 1.75rem;
    max-height: calc(100vh - 3.75rem);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.3s linear;
    &.menuIsOpen {
      right: 0;
    }
  }

  .appBarButtons {
    flex-direction: column;
    margin-top: 3.125rem;
    margin-left: 0;
    gap: 0;
  }

  .appBarReqButton {
    order: 2;
    margin: 1.25rem;
  }

  .authButtonsMobile {
    flex: 1 1;
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;

    & button {
      min-width: 121px;
      font-size: 14px;
    }
  }

  .menuToggle {
    width: 1.25rem;
    height: 0.9rem;
    margin-left: auto;
    position: relative;
    cursor: pointer;

    span {
      background-color: $grey900;
      position: absolute;
      border-radius: 3px;
      transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
      width: 100%;
      height: 0.1525rem;
      transition-duration: 500ms;
      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        top: 0.375rem;
        left: 0;
        opacity: 1;
      }

      &:nth-child(3) {
        bottom: 0;
        left: 0;
      }
    }
    &.menuIsOpen {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0.375rem;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 0.375rem;
        }
      }
    }
  }
}
