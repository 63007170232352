@import '@/styles/functions.module.scss';
@import '@/styles/palette.module.scss';

.image {
  width: 100%;
  height: auto;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

.wrapper {
  width: 100%;
  position: relative;
}

.lottie {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
