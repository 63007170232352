@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

.wrapper {
  background-color: rgba(255, 80, 0, 0.05);
}

.container {
  width: 100%;
  max-width: toRem(1154);
  margin: 0 auto;
  color: $white;
  border-radius: toRem(26);
  padding: toRem(86) toRem(20) toRem(94) toRem(20);
  position: relative;
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.title {
  margin-bottom: toRem(10);
  text-align: center;
  color: $grey900;
  white-space: pre;
}

.subtitle {
  margin-bottom: toRem(20);
  color: $grey900;
}

.button {
  width: toRem(380);
  height: toRem(50);
  border-radius: toRem(10);
  font-size: toRem(20);
  font-weight: bold;
}

.baseText {
  text-align: center;
  margin-top: toRem(10);
}

@include respond(mobile) {
  .container {
    max-width: 100%;
    border-radius: 0;
    padding: toRem(30) toRem(20) toRem(50);
  }

  .leftBgIcon,
  .rightBgIcon {
    display: none;
  }

  .title {
    font-size: toRem(27);
    margin-bottom: toRem(15);
    white-space: unset;
    br {
      display: none;
    }
  }

  .subtitle {
    text-align: center;
    font-size: toRem(17);
  }

  .button {
    width: 100%;
    height: toRem(40);
    font-size: toRem(15);
    border-radius: toRem(8);
  }
  .baseText {
    display: none;
  }
}
