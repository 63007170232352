@import '../../styles/functions.module.scss';

.lineBackgroundRoot {
  display: flex;
  overflow: hidden;
}

.lineBackgroundCol {
  width: toRem(73);
  flex: 1;
  opacity: 0.14;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 80, 1, 0.75),
    rgba(255, 243, 237, 0) 96%
  );
  &:nth-child(1) {
    height: toRem(144);
  }

  &:nth-child(2) {
    height: toRem(240);
  }

  &:nth-child(3) {
    height: toRem(142);
  }

  &:nth-child(4) {
    height: toRem(311);
  }

  &:nth-child(5) {
    height: toRem(184);
  }
  &:nth-child(6) {
    height: toRem(96);
  }
  &:nth-child(7) {
    height: toRem(240);
  }
  &:nth-child(8) {
    height: toRem(143);
  }
  &:nth-child(9) {
    height: toRem(184);
  }
  &:nth-child(10) {
    height: toRem(329);
  }
  &:nth-child(11) {
    height: toRem(95);
  }
  &:nth-child(12) {
    height: toRem(270);
  }
  &:nth-child(13) {
    height: toRem(144);
  }
  &:nth-child(14) {
    height: toRem(178);
  }
  &:nth-child(15) {
    height: toRem(350);
  }
  &:nth-child(16) {
    height: toRem(227);
  }
  &:nth-child(17) {
    height: toRem(302);
  }
  &:nth-child(18) {
    height: toRem(178);
  }
  &:nth-child(19) {
    height: toRem(353);
  }
  &:nth-child(20) {
    height: toRem(124);
  }
}
